import React from "react";
import logo from "../assets/images/logo.svg";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";

export default function Footer(props) {
  return (
    <footer>
      <div className="container">
        <div className="footer">
          <div className="row">
            <div className="col-lg-4">
              <img src={logo} className="img-fluid brand_logo" alt="logo" />
            </div>
            <div className="col-lg-8">
              <ul className="align" >
                <li>
                  <Link
                    to="about"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    offset={-100}
                  >
                    About
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link
                    to="howbuy"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    offset={-100}
                  >
                    How to Buy
                  </Link>
                </li>
                <li>
                  <Link
                    to="chart"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    offset={-100}
                  >
                    Tokenomics
                  </Link>
                </li>
                <li>
                  <a
                    href={require("../assets/pdf/KONG_Whitepaper.pdf")}
                    target="_blank"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    offset={-100}
                  >
                    Whitepaper
                  </a>
                </li>
                <li>
                  <Link
                    to="roadmap"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    offset={-100}
                  >
                    Roadmap
                  </Link>
                </li>
                {/* <li className="pc_view">
                  <Link
                    to="roadmap"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    offset={-100}
                  >
                    Roadmap
                  </Link>
                </li>
                <li className="mob_view">
                  <Link
                    to="roadmap_mob"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    offset={-100}
                  >
                    Roadmap
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="faq"
                    spy={true}
                    smooth={true}
                    duration={250}
                    color="transparent"
                    offset={-100}
                  >
                    FAQs
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer_blw">
            <div className="flx">
              <div>
                <ul>
                  <li>
                    <a href="https://twitter.com/KONG_Memecoin" target="_blank">
                      <i className="fa-brands fa-x-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://discord.gg/kgjv8M6CAP" target="_blank">
                      <i className="fa-brands fa-discord"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://drive.google.com/file/d/1lm3o2Wv-C5mkWArkFB8pHCxTKvk-ElOy/view"
                      target="_blank"
                    >
                      <i className="fa-regular fa-file-lines"></i>
                    </a>
                  </li>

                </ul>
              </div>
              <div>
                <p>
                  Copyright © {new Date().getFullYear()} $KONG. All rights
                  reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer >
  );
}

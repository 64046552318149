import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import logo from "../assets/images/logo.svg";
import hamburger from "../assets/images/hamburger.svg";
import $ from "jquery";

import Web3 from "web3";

import { toast } from "react-toastify";

import { setBalances } from "../actions/user";
import { getAllowanceBalance } from "../helper/calculation";

import { walletClientToSigner } from "../pages/ethersConnect.js";
import config from "../lib/config";

import {
  useWalletClient,
  useDisconnect,
  useConnect,
  useNetwork,
  useAccount,
  useSwitchNetwork,
} from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { setWallet } from "../reducers/Actions";

import { formatNumber } from "../helper/calculation";

let toasterOption = config.toasterOption;

export default function Navbar(props) {
  const dispatch = useDispatch();

  const { disconnect } = useDisconnect();
  const { connect, connectors } = useConnect();

  const walletConnection = useSelector((state) => state.walletConnection);
  const userBalances = useSelector((state) => state.balances);

  const [balanceDetails, setbalanceDetails] = useState({
    tokenbalance: 0,
    balance: 0,
    kongbalance: 0,
  });

  const network = useSwitchNetwork({
    onMutate(args) {
      console.log("Mutate", args);
    },
    onError(error) {
      console.log("Error", error);
    },
  });

  useAccount({
    onConnect: ({ address, connector, isReconnected }) => { },
    onDisconnect: () => {
      console.log("Disconnected");
      var web3 = new Web3(config.rpcUrl);
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: "",
          provider: null,
          connect: "",
          isChange: "",
        })
      );
      setTimeout(function () {
        window.location.reload(false);
      }, 1500);
    },
  });

  const { chain, chains } = useNetwork();

  let chainId = config.NetworkId;
  const { data: walletClient } = useWalletClient({ chainId });

  useEffect(() => {
    setConnection();
  }, [walletClient, chain?.network]);

  useEffect(() => {
    loadBalances();
  }, [userBalances]);

  function loadBalances() {
    setbalanceDetails({
      tokenbalance: userBalances.tokenbalance,
      balance: userBalances.balance,
      kongbalance: userBalances.kongbalance,
    });
  }

  async function setConnection() {
    if (walletClient && chain && chain.id == config.NetworkId) {
      var { transport } = walletClientToSigner(walletClient);
      var web3 = new Web3(transport);

      if (chain.id == config.NetworkId) {
        dispatch(
          setWallet({
            network: config.NetworkId,
            web3: web3,
            address: walletClient.account.address,
            provider: transport,
            connect: "yes",
            isChange: "false",
          })
        );
      }
    } else {
      var web3 = new Web3(config.rpcUrl);
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: "",
          provider: null,
          connect: "",
          isChange: "",
        })
      );
    }

    setTimeout(async function () {
      try {
        var { balanceOf, allowance, bnbBal, kongbalanceOf, isLoad } =
          await getAllowanceBalance();
        dispatch(
          setBalances({
            tokenbalance: balanceOf,
            tokenallowance: allowance,
            balance: bnbBal,
            kongbalance: kongbalanceOf,
            isLoad: isLoad,
          })
        );
      } catch (err) { }
    }, 500);
  }

  async function connectfunction(connector) {
    try {
      let check = isMobile();

      if (
        check &&
        !window.ethereum &&
        connector &&
        connector.connector.id != "walletConnect"
      ) {
        await connectMetamaskMobile();
        return;
      }
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        var currnetwork = await web3.eth.net.getId();
        console.log(currnetwork, config.NetworkId, ">>>>>>>????");
        if (
          parseInt(currnetwork) != config.NetworkId &&
          connector.connector.id == "metaMask"
        ) {
          toast.error("Please Connect Ethereum Mainet", toasterOption);
          return;
        }
      }
      console.log(connector, 'connectorconnectorconnector')
      var resp = connect(connector);

      window.$("#connect_wallet_modal").modal("hide");
      setTimeout(function () {
        //window.location.reload(false);

        $('#btn-close').click();

      }, 1000);
    } catch (err) {
      console.log(err, 'errerrerrerrerr')
      var errMsg = (err && err.message) ? err.message : err;
      var pos = errMsg.search("chains are not supported");
      var msg = "Please try again later";
      if (pos >= 0) {
        msg = "Please switch ethereum wallet";
      }
      toast.error(msg, toasterOption);
    }
  }

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  function isMobile() {
    //eslint-disable-next-line
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }

  const connectMetamaskMobile = () => {
    const dappUrl = window.location.href.split("//")[1].split("/")[0];
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;
    window.open(metamaskAppDeepLink, "_self");
  };

  async function disconnectMetamask() {
    localStorage.clear();

    disconnect();

    setTimeout(function () {
      window.location.reload(false);
    }, 1000);
  }

  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }

  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-xl main_navbar navbar-sticky">
        <div className="container">
          <Link className="navbar-brand" to="#">
            <img src={logo} className="img-fluid brand_logo" alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <img src={hamburger} className="img-fluid" />
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                {/* After Login Menus */}
                {/* <div className="dropdown me-3">
                  <a
                    className="primary_btn dropdown-toggle address_btn"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    0x75cf28f29266a413ec878ce5c4a1c9682c819586
                  </a>
                  <ul
                    className="dropdown-menu walletdrop "
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        <label>BUSD Balance:</label>
                        <span className="ms-2">250.08 </span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Dashboard
                      </a>
                    </li>
                  </ul>
                </div>
                <button className="primary_btn">Logout</button> */}
                <ul>
                  <li>
                    <a
                      href="https://www.nature.org/en-us/get-involved/how-to-help/plant-a-billion/"
                      target="_blank"
                    >
                      $KONG Utility
                    </a>
                  </li>
                  <li>
                    <Link
                      to="about"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="howbuy"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      How to Buy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="chart"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Tokenomics
                    </Link>
                  </li>
                  <li>
                    <a
                      href={require("../assets/pdf/KONG_Whitepaper.pdf")}
                      target="_blank"
                    >
                      Whitepaper
                    </a>
                  </li>
                  {/*
                  <li className="pc_view">
                    <Link
                      to="roadmap"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Roadmap
                    </Link>
                  </li>

                   <li className="mob_view">
                    <Link
                      to="roadmap_mob"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Roadmap
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="faq"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="roadmap"
                      spy={true}
                      smooth={true}
                      duration={250}
                      color="transparent"
                      offset={-100}
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      Roadmap
                    </Link>
                  </li>
                  <li>
                    <a
                      href={require("../assets/pdf/audit.pdf")}
                      target="_blank"
                    >
                      Audit
                    </a>
                  </li>
                  {walletConnection && walletConnection.address == "" ? (
                    <>
                      <li>
                        <button
                          className="primary_btn"
                          data-bs-toggle="modal"
                          data-bs-target="#connect_wallet_modal"
                        >
                          Connect Wallet
                        </button>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="nav-item dropdown">
                        <Link
                          to="#"
                          className="nav-link dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          {halfAddrShow(walletConnection?.address)}
                        </Link>
                        <div className="dropdown-menu">
                          <Link to="#" className="dropdown-item">
                            YOUR BALANCE :
                          </Link>
                          <Link to="#" className="dropdown-item">
                            ETH : {formatNumber(balanceDetails.balance, 5)}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            USDT :{" "}
                            {formatNumber(balanceDetails.tokenbalance, 5)}
                          </Link>
                          <Link to="#" className="dropdown-item">
                            $KONG Token :{" "}
                            {formatNumber(balanceDetails.kongbalance, 5)}
                          </Link>
                          <div className="dropdown-divider"></div>
                          <Link
                            to="#"
                            className="dropdown-item"
                            onClick={() => disconnectMetamask()}
                          >
                            Logout
                          </Link>
                        </div>
                      </li>
                    </>
                  )}
                </ul>
                <ul className="mobileSocialNavbar">
                  <li>
                    <a href="https://twitter.com/KongMemecoin" target="_blank">
                      <i className="fa-brands fa-x-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://discord.gg/kgjv8M6CAP" target="_blank">
                      <i className="fa-brands fa-discord"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://drive.google.com/file/d/1lm3o2Wv-C5mkWArkFB8pHCxTKvk-ElOy/view"
                      target="_blank"
                    >
                      <i className="fa-regular fa-file-lines"></i>
                    </a>
                  </li>
                </ul>
                {/* <button
                  className="primary_btn"
                  data-bs-toggle="modal"
                  data-bs-target="#connect_wallet_modal"
                >
                  Connect Wallet
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
        data-bs-dismiss="modal"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  {connectors &&
                    connectors.length > 0 &&
                    connectors.map((connector, index) => (
                      <div key={index}>
                        {connector && connector.id == "metaMask" ? (
                          <a target="_blank" className="mb-3 d-block">
                            <button
                              type="button"
                              className="connect_wallet_button"
                              onClick={() => connectfunction({ connector })}
                            >
                              <img
                                src={require("../assets/images/metamask.png")}
                                height="28"
                                width="28"
                                className="img-fluid"
                              />{" "}
                              <span>MetaMask</span>
                            </button>
                          </a>
                        ) : (
                          ""
                        )}
                        {connector && connector.id == "walletConnect" ? (
                          <a target="_blank" className="mb-3 d-block">
                            <button
                              type="button"
                              className="connect_wallet_button"
                              onClick={() => connectfunction({ connector })}
                            >
                              <img
                                src={require("../assets/images/trustwallet.png")}
                                height="28"
                                width="28"
                                className="img-fluid"
                              />{" "}
                              <span>Trust Wallet</span>
                            </button>
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { useDispatch } from "react-redux";
import { connection } from "../helper/connection";
import { useSelector } from "react-redux";
import { setBalances, getFaqList } from "../actions/user";

import {
  tokenBusdPrice,
  calculatePrice,
  numberFloatOnly,
  formatNumber,
  getAllowanceBalance,
  calculateKongPrice,
  gethardcap,
  convert
} from "../helper/calculation";
import { toastAlert } from "../helper/toastAlert";

import ConfirmBuy from "../components/ConfirmBuy";
import HistoryList from "../components/HistoryList";

// Scroll to Top
function ScrollToTopOnMount() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return null;
}

export default function Home() {
  const Dispatch = useDispatch();
  const userBalances = useSelector((state) => state.balances);
  const walletConnection = useSelector((state) => state.walletConnection);

  const [provider, setprovider] = useState("");
  const [address, setaddress] = useState("");

  const [tokenRate, settokenRate] = useState(0);
  const [amount, setamount] = useState("");
  const [receivedAmount, setreceivedAmount] = useState("");
  const [isLoad, setisLoad] = useState(true);
  const [showerror, setshowerror] = useState(false);
  const [showerror1, setshowerror1] = useState(false);
  const [tokenValue, settokenValue] = useState(0);
  const [faqList, setfaqList] = useState([]);
  const [balanceHardcap, setbalanceHardcap] = useState(0);

  const [balanceDetails, setbalanceDetails] = useState({
    tokenbalance: 0,
    balance: 0,
    kongbalance: 0,
  });

  useEffect(() => {
    getFaq();
  }, []);

  useEffect(() => {
    getmyprovider();
    getValue();
  }, [walletConnection]);

  useEffect(() => {
    loadBalances();
  }, [userBalances]);

  async function getFaq() {
    var { result } = await getFaqList();
    setfaqList(result);
  }

  function loadBalances() {
    setbalanceDetails({
      tokenbalance: userBalances.tokenbalance,
      balance: userBalances.balance,
      kongbalance: userBalances.kongbalance,
    });
  }

  const getValue = async () => {
    try {
      await getRate();
      setisLoad(false);
    } catch (err) {
      setisLoad(true);
    }
  };

  useEffect(() => {
    AOS.init();
  }, []);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
  };

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  var abv_slide = {
    slidesToShow: 7,
    swipeToSlide: true,
    focusOnSelect: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };
  var blw_slide = {
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => { }, [provider, address]);

  async function getRate() {

    let { price } = await tokenBusdPrice();
    var oneusdt = 1 * 10 ** 6;
    var { perToken } = await calculateKongPrice(price, oneusdt);
    perToken = formatNumber(perToken, 18);
    settokenValue(perToken);
    settokenRate(price);

    let { availablePurchase } = await gethardcap();
    setbalanceHardcap(availablePurchase);
  }

  async function getmyprovider() {
    var { provider, address } = await connection();
    setprovider(provider);
    setaddress(address);
  }

  const handlechange = async (e) => {
    let value = e.target.value;
    calculateValue(value);
  };

  async function calculateValue(value) {
    setshowerror(false);
    setshowerror1(false);
    var status = numberFloatOnly(value);
    if (status) {
      var amt = parseFloat(value) * 10 ** 6;
      let { perToken } = await calculatePrice(tokenRate, amt);
      setamount(value);
      perToken = convert(perToken);
      setreceivedAmount(perToken);

      if (parseFloat(balanceHardcap) < parseFloat(value)) {
        setshowerror1(true);
      } else if (parseFloat(balanceDetails.tokenbalance) < parseFloat(value)) {
        setshowerror(true);
      }

    } else if (value === "") {
      setamount("");
      setreceivedAmount("");
    } else if (!status) {
      setamount("");
      setreceivedAmount("");
    }
  }

  async function buyToken() {
    console.log("buyToken");
    if (parseFloat(balanceDetails.tokenbalance) < parseFloat(amount)) {
      toastAlert("error", "Sorry Insufficient USDT balance");
      return;
    }
    window.$("#buytoken_confirm_modal").modal("show");
  }

  async function loadData1() {
    setamount("");
    setreceivedAmount("");

    try {
      var { balanceOf, allowance, bnbBal, kongbalanceOf, isLoad } =
        await getAllowanceBalance();
      Dispatch(
        setBalances({
          tokenbalance: balanceOf,
          tokenallowance: allowance,
          balance: bnbBal,
          kongbalance: kongbalanceOf,
          isLoad: isLoad,
        })
      );

      let { availablePurchase } = await gethardcap();
      setbalanceHardcap(availablePurchase);

    } catch (err) { }
  }

  function maxAmount() {
    calculateValue(userBalances.tokenbalance);
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header">
        <div className="container">
          <h1>
            Not Another PEPE.
            <br />
            This is $KONG, We either Win or Win.
          </h1>
          <img
            src={require("../assets/images/banimg.png")}
            className="img-fluid banimg"
          />

          <div className="header_box">
            <>
              <div className="flx_head">
                <div>
                  <h3 className="h3tag">IDO Pre-Sale is Live</h3>
                </div>
              </div>
              <p className="mt-3 mb-0">
                Current Price: 1 USDT = <b>{tokenValue} $KONG</b>
              </p>

              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="flex_label">
                      <label>Enter Amount</label>
                      <label>
                        Balance: {formatNumber(balanceDetails.tokenbalance, 5)}
                      </label>
                    </div>
                    <div className="input-group mb-3 lft">
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        onChange={handlechange}
                        name="USDTValue"
                        value={amount ? amount : ""}
                      />
                      <button
                        className="input-group-text"
                        onClick={() => maxAmount()}
                      >
                        Max
                      </button>
                      <span className="input-group-text">USDT</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="flex_label">
                      <label>You Will Get</label>
                      <label>
                        Balance: {formatNumber(balanceDetails.kongbalance, 5)}
                      </label>
                    </div>
                    <div className="input-group mb-3 rgt">
                      <input
                        type="text"
                        className="form-control"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={receivedAmount ? receivedAmount : ""}
                        disabled={isLoad}
                      />
                      <span className="input-group-text">$KONG Token</span>
                    </div>
                  </div>
                </div>
              </div>
              {amount &&
                receivedAmount &&
                parseFloat(receivedAmount) > 0 &&
                parseFloat(amount) > 0 ? (
                <button
                  className="primary_btn"
                  disabled={(isLoad || showerror || showerror1)}
                  onClick={() => buyToken()}
                >
                  Buy Tokens Now
                </button>
              ) : (
                <button className="primary_btn grey_btn" disabled>
                  Buy Tokens Now
                </button>
              )}
            </>
          </div>
          {showerror && (
            <p className="text-danger mb-0 mt-2">
              <i className="fa-solid fa-circle-exclamation me-2"></i>
              Insufficient USDT Balance
            </p>
          )}
          {showerror1 && (
            <p className="text-danger mb-0 mt-2">
              <i className="fa-solid fa-circle-exclamation me-2"></i>
              Maximum limit {balanceHardcap} USDT
            </p>
          )}
        </div>
      </div>
      <div className="main_wrapper">
        <HistoryList />
        <section className="about" name="about">
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto">
                <h2 className="h2tag pb-3">About $KONG</h2>
                <p>
                  $KONG: Bridging Memes and Blockchain - A King Kong
                  Meme-Inspired Cryptocurrency
                </p>
                <p>
                  $KONG is an innovative and community-driven cryptocurrency
                  that unites the amusement and allure of internet memes with
                  the transformative power of blockchain technology. By fusing
                  these two distinct worlds, $KONG aims to establish a dynamic
                  and lively ecosystem that fosters user engagement, seamless
                  transactions, and active involvement in meme culture, all
                  while harnessing the numerous advantages offered by blockchain
                  technology.
                </p>
              </div>
              <div className="col-md-6 m-auto text-center">
                <img
                  src={require("../assets/images/about.png")}
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="howbuy" name="howbuy">
          <div className="container">
            <h2 className="h2tag text-center pb-3">How to buy $KONG</h2>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <ul>
                  <li>
                    <span>1</span>
                    <p>
                      $KONG: A King Kong Meme-Inspired Cryptocurrency. Not
                      another unfavorable coin.
                    </p>
                  </li>
                  <li>
                    <span>2</span>
                    <p>
                      Go to{" "}
                      <a href="#" target="_blank">
                        App store
                      </a>{" "}
                      or{" "}
                      <a href="#" target="_blank">
                        Play store
                      </a>{" "}
                      to download MetaMask, or Trust Wallet.
                    </p>
                  </li>
                  <li>
                    <span>3</span>
                    <p>
                      Connect your Wallet, paste the $KONG Token Address, and
                      load up $KONG Coins instantly.
                    </p>
                  </li>
                  <li className="mb-0">
                    <span>4</span>
                    <p>
                      That's it! Sit Back, Watch, and Anticipate the Magic done.
                      King Kong in action!
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="chart" name="chart">
          <div className="container">
            <h2 className="h2tag text-center">
              Tokenomics Distribution & Allocation
            </h2>
            <div className="row">
              <div className="col-md-9 mx-auto text-center">
                <img
                  src={require("../assets/images/chart.png")}
                  className="img-fluid pc_view"
                />
                <img
                  src={require("../assets/images/chart_mob.png")}
                  className="img-fluid mob_view"
                />
                <ul>
                  <li>
                    <div className="box"></div>
                    <p>
                      15%<span> Marketing</span>
                    </p>
                  </li>
                  <li>
                    <div className="box"></div>
                    <p>
                      10%<span> Team</span>
                    </p>
                  </li>
                  <li>
                    <div className="box"></div>
                    <p>
                      10%<span> Platform </span>
                    </p>
                  </li>
                  <li>
                    <div className="box"></div>
                    <p>
                      20%<span> Presale </span>
                    </p>
                  </li>
                  <li>
                    <div className="box"></div>
                    <p>
                      25%<span> Liquidity</span>
                    </p>
                  </li>
                  <li>
                    <div className="box"></div>
                    <p>
                      20%<span> Sale</span>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="roadmap pc_view" name="roadmap">
          <div className="container">
            <h2 className="h2tag">Roadmap</h2>
          </div>
          <div className="roadmapbg">
            <div className="container">
              <p className="ctn">
                Please be aware that this can change anytime due to volatility
                of the market and any changes shall be announced and agreed upon
                by the community
              </p>
              <div className="sliderbg">
                <Slider {...settings}>
                  <div>
                    <div className="box now">
                      <div className="leftctn">End of Q2 2023</div>
                      <p className="end">End of Q3 2023</p>
                      <h6>Now</h6>
                      <ol>
                        <li>Launch $KONG Token securely.</li>
                        <li>Initiate Community Engagement</li>
                        <li>
                          Establish vibrant social media channels and community.
                        </li>
                        <li>List on Decentralized Exchanges (DEXs)</li>
                      </ol>
                    </div>
                  </div>
                  <div>
                    <div className="box">
                      <p className="end">Mid Q4 2023</p>
                      <div>
                        <h6>Milestone 1</h6>
                        <ol>
                          <li>Community Governance Implementation</li>
                          <li>Explore DeFi Integrations with other projects</li>
                          <li>Partnership Expansion</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="box bdrheight">
                      <p className="end">End of Q1 2024</p>
                      <div>
                        <h6>Milestone 2</h6>
                        <ol>
                          <li>Integrate with NFT Marketplace (Marketplac3)</li>
                          <li>Security Enhancements</li>
                          <li>Philanthropic Initiatives</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="box">
                      <p className="end">Mid Q2 2024</p>
                      <div>
                        <h6>Milestone 3</h6>
                        <ol>
                          <li>
                            Reward System Implementation (Staking & Yield
                            farming)
                          </li>
                          <li>Enhanced Functionality</li>
                          <li>Expanding Marketing Efforts</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="box">
                      <p className="end">End of Q3 2024</p>
                      <div>
                        <h6>Milestone 4</h6>
                        <ol>
                          <li>Explore Real-World Use Cases</li>
                          <li>
                            Global Outreach (Expanding to the international
                            market & Collaborate with global communities){" "}
                          </li>
                          <li>Educational Initiatives</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="box">
                      <p className="end">Mid Q4 2024</p>
                      <div>
                        <h6>Milestone 5</h6>
                        <ol>
                          <li>Integration with Major Platforms</li>
                          <li>Token Utility Enhancements</li>
                          <li>Continued Community Building</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="box bdrheight">
                      <div>
                        <h6>Milestone 6</h6>
                        <ol>
                          <li>Cross-Chain Interoperability </li>
                          <li>Ecosystem Expansion</li>
                          <li>Sustainability and Long-Term Planning</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="roadmapmob mob_view w-100" name="roadmap_mob">
          <div className="container">
            <h2 className="h2tag">Roadmap</h2>
            <p className="ctn">
              Please be aware that this can change anytime due to volatility of
              the market and any changes shall be announced and agreed upon by
              the community
            </p>
            <Slider
              className="abv_slide"
              asNavFor={nav1}
              ref={(slider2) => setNav2(slider2)}
              {...abv_slide}
            >
              <div>
                {" "}
                <div className="box">Now</div>
              </div>
              <div>
                {" "}
                <div className="box">ML1</div>
              </div>
              <div>
                {" "}
                <div className="box">ML2</div>
              </div>
              <div>
                {" "}
                <div className="box">ML3</div>
              </div>
              <div>
                {" "}
                <div className="box">ML4</div>
              </div>
              <div>
                {" "}
                <div className="box">ML5</div>
              </div>
              <div>
                {" "}
                <div className="box">ML6</div>
              </div>
            </Slider>
            <Slider
              asNavFor={nav2}
              ref={(slider1) => setNav1(slider1)}
              vertical={true}
              verticalSwiping={true}
              slidesToShow={1}
              infinite={false}
              className="blw_slide"
              {...blw_slide}
            >
              <div>
                <div className="box now">
                  <p className="end">End of Q2 2023</p>
                  <h6>Now</h6>
                  <ol>
                    <li>Launch $KONG Token securely.</li>
                    <li>Initiate Community Engagement</li>
                    <li>
                      Establish vibrant social media channels and community.
                    </li>
                    <li>List on Decentralized Exchanges (DEXs)</li>
                  </ol>
                </div>
              </div>
              <div>
                <div className="box">
                  <p className="end">End of Q3 2023</p>
                  <div>
                    <h6>Milestone 1</h6>
                    <ol>
                      <li>Community Governance Implementation</li>
                      <li>Explore DeFi Integrations with other projects</li>
                      <li>Partnership Expansion</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div>
                <div className="box bdrheight">
                  <p className="end">Mid Q4 2023</p>
                  <div>
                    <h6>Milestone 2</h6>
                    <ol>
                      <li>Integrate with NFT Marketplace (Marketplac3)</li>
                      <li>Security Enhancements</li>
                      <li>Philanthropic Initiatives</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div>
                <div className="box">
                  <p className="end">End of Q1 2024</p>
                  <div>
                    <h6>Milestone 3</h6>
                    <ol>
                      <li>
                        Reward System Implementation (Staking & Yield farming)
                      </li>
                      <li>Enhanced Functionality</li>
                      <li>Expanding Marketing Efforts</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div>
                <div className="box">
                  <p className="end">Mid Q2 2024</p>
                  <div>
                    <h6>Milestone 4</h6>
                    <ol>
                      <li>Explore Real-World Use Cases</li>
                      <li>
                        Global Outreach (Expanding to the international market &
                        Collaborate with global communities){" "}
                      </li>
                      <li>Educational Initiatives</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div>
                <div className="box">
                  <p className="end">End of Q3 2024</p>
                  <div>
                    <h6>Milestone 5</h6>
                    <ol>
                      <li>Integration with Major Platforms</li>
                      <li>Token Utility Enhancements</li>
                      <li>Continued Community Building</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div>
                <div className="box bdrheight">
                  <p className="end">Mid Q4 2024</p>
                  <div>
                    <h6>Milestone 6</h6>
                    <ol>
                      <li>Cross-Chain Interoperability </li>
                      <li>Ecosystem Expansion</li>
                      <li>Sustainability and Long-Term Planning</li>
                    </ol>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section> */}

        <section className="faq" name="faq">
          <div className="container">
            <h2 className="h2tag text-center pb-2">FAQs</h2>
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <div className="accordion" id="accordionExample">
                  {faqList &&
                    faqList.length > 0 &&
                    faqList.map((item, i) => {
                      return (
                        <div className="accordion-item" key={i}>
                          <h2
                            className="accordion-header"
                            id={"headingOne-" + i}
                          >
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={"#collapseOne-" + i}
                              aria-expanded="true"
                              aria-controls={"collapseOne-" + i}
                            >
                              {item.question}
                            </button>
                          </h2>
                          <div
                            id={"collapseOne-" + i}
                            className={
                              i == 0
                                ? "accordion-collapse collapse show"
                                : "accordion-collapse collapse"
                            }
                            aria-labelledby={"headingOne-" + i}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">{item.answer}</div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="img_sec" name="roadmap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <img src={require("../assets/images/kong_roadmap.jpg")} alt="Kong Roadmap" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>

        <section className="img_sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <img src={require("../assets/images/audit_cert.png")} alt="Audit Certificate" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>

        <section className="img_sec">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <img src={require("../assets/images/kyc_cert.png")} alt="Audit Certificate" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>

      </div>
      <ConfirmBuy
        receivedAmount={receivedAmount}
        amount={amount}
        loadData={loadData1}
      />
      <Footer />

      {/* Buy Token Confirm Modal */}
    </div>
  );
}
